import * as React from "react"

/** Returns React children with primitive nodes wrapped in a `<span data-text>`. */
export const toWrappedChildren = (children: React.ReactNode | React.ReactNode[]) => {
  const childNodes = React.Children.toArray(children).map((child, key) =>
    child == null || typeof child === "object" || typeof child === "function"
      ? child
      : React.createElement(
          "span",
          {
            "data-text": "",
            key,
          },
          child,
        ),
  )

  return childNodes.length === 1 ? childNodes[0] : childNodes
}

/** Returns React props with primitive children nodes wrapped in a `<span data-text>`. */
export const withWrappedChildren = <T extends object>({ ...props }: T): T => {
  if ("children" in props) {
    props.children = toWrappedChildren(props.children as any)
  }

  return props
}
