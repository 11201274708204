import * as React from "react"

import { cva, type VariantProps } from "./cva.ts"
import { toWrappedChildren } from "./to-wrapped-children.ts"

export const Button = React.forwardRef<
  HTMLButtonElement,
  VariantProps<typeof classForButton> & React.ComponentPropsWithoutRef<"button">
>(({ children, className, disabled, variant, size, ...props }, ref) => {
  return (
    <button
      data-ui="button"
      className={classForButton({ disabled, variant, size, className })}
      disabled={disabled}
      ref={ref}
      {...props}
    >
      {toWrappedChildren(children)}
    </button>
  )
})

Button.displayName = "Button"

export const classForButton = cva(
  [
    "inline-flex gap-1.5 items-center justify-center flex-grow-0",
    "text-sm/4",
    "transition-all select-none",
    "outline-none",
    "focus-visible:ring-2 focus-visible:ring-offset-1 focus-visible:ring-blue-12",
    "active:ring-2 active:ring-offset-1",
    "[&:is(a)]:no-underline",
    "[&>[data-text]]:contents",
  ],
  {
    variants: {
      variant: {
        primary: [],
        secondary: [],
        outline: [
          "bshadow",
          "hover:not(:active):[background-color:color-mix(in_srgb,var(--foreground)_7.06%,transparent)]",
        ],
        ghost: ["hover:bg-gray-4", "focus-visible:bg-gray-4"],
        destructive: ["bshadow"],
      },
      size: {
        sm: "p-1.5 hasnt-[>svg:only-child]:px-2 rounded-md",
        md: "p-2.5 hasnt-[>svg:only-child]:px-3 rounded-md",
        lg: "p-4 hasnt-[>svg:only-child]:px-10",
      },
      disabled: {
        true: "pointer-events-none",
      },
    },
    compoundVariants: [
      // compounds
      {
        variant: ["primary", "secondary", "outline", "ghost"],
        disabled: false,
        className: ["active:ring-gray-9"],
      },
      // primary & secondary compounds
      {
        variant: "primary",
        disabled: false,
        className: [
          "bg-gray-12 text-gray-1",
          "hover:[background-color:color-mix(in_srgb,var(--gray-12)_80%,transparent)]",
          "focus-visible:[background-color:color-mix(in_srgb,var(--gray-12)_80%,transparent)]",
          "active:bg-gray-12",
        ],
      },
      {
        variant: "secondary",
        disabled: false,
        className: [
          "bg-gray-3 text-gray-12",
          "hover:bg-gray-6",
          "focus-visible:bg-gray-6",
          "active:bg-gray-5",
        ],
      },
      {
        variant: ["primary", "secondary"],
        disabled: true,
        className: "bg-gray-3 text-gray-8",
      },
      // outline compounds
      {
        variant: "outline",
        disabled: false,
        className: "text-gray-12",
      },
      {
        variant: "outline",
        disabled: true,
        className: "text-gray-8",
      },
      {
        variant: "outline",
        size: ["sm", "md"],
        className: "bshadow-gray-6 hover:bshadow-gray-7 active:bshadow-gray-8",
      },
      {
        variant: "outline",
        size: "lg",
        className: "bshadow-gray-12",
      },
      // ghost compounds
      {
        variant: "ghost",
        disabled: false,
        className: ["text-gray-11", "active:text-gray-12"],
      },
      {
        variant: "ghost",
        disabled: true,
        className: "text-gray-8",
      },
      // destructive compounds
      {
        variant: "destructive",
        disabled: false,
        className: [
          "bg-tomato-2 text-tomato-11 bshadow-tomato-4",
          "hover:bg-tomato-4 hover:bshadow-tomato-6",
          "active:bshadow-tomato-8 active:ring-tomato-9",
        ],
      },
      {
        variant: "destructive",
        disabled: true,
        className: "text-tomato-6 bg-tomato-1 bshadow-tomato-3",
      },
    ],
    defaultVariants: {
      disabled: false,
      size: "md",
      variant: "primary",
    },
  },
)
